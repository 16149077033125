<template>
  <div class="d-flex align-center">
    <base-avatar v-if="authorMedia" size="35px">
      <base-image
        :media="authorMedia"
        loading="lazy"
        height="35"
        width="35"
        :init-width="35"
        :alt="cmsAuthor.displayName"
      />
    </base-avatar>
    <div class="ml-3 text-body-4">{{ cmsAuthor.displayName }}</div>
  </div>
</template>

<script>
import BaseAvatar from '~/components/base/avatar/BaseAvatar'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CmsElementBsBlogDetailAuthor',
  components: { BaseImage, BaseAvatar },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cmsAuthor() {
      return this.content.data
    },
    authorMedia() {
      return this.cmsAuthor?.media
    },
  },
}
</script>
